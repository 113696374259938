import deburr from 'lodash/deburr';

const matchSearch = (search, object) => {
  return (
    deburr(JSON.stringify(object))
      .toLowerCase()
      .indexOf(deburr(search ?? '').toLowerCase()) !== -1
  );
};

export { matchSearch };
